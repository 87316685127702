import { useMemo, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import cn from 'classnames'
import { Text } from '@components/ui'
import Button from '@components/control/Button'
import { atobID, commerceEnabled, addQueryParam } from '@lib/utils/tools'
import usePrice from '@shopify/product/use-price'
import Picture from '@components/ui/Picture'
import { useCommerce } from '@commerce/index'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { Close } from '@components/icons/next'
import { useVariant } from '@shopify/product/use-variant'
import { useInitializedOptionsState } from '@shopify/product/use-options'
import { useUI } from '@components/ui'
import { LearnMore } from '@components/common'
import { ProductOptions } from '@components/product'
import ProductSwiper from '@components/product/ProductSwiper'
import Affirm from '@components/product/Affirm'
import { ProductPrice, OutStockNotify } from '@components/product'
import { useAddItem, useCodeApply } from 'lib/shopify/api/cart'
import { getQuery } from '@lib/utils/cookies'
import Cookies from 'js-cookie'
import { serializeObject } from '@lib/utils/tools'
import clipboard from 'copy-to-clipboard'
import { createCheckout } from 'lib/shopify/api/checkout'
import { getAmazonLinkParams, pageTwttr } from '@lib/utils/thirdparty'
import {
  buyNowGTM,
  listBuyNowGTM,
  addToCartGTM,
  listAddToCartGTM,
  viewItemGTM,
} from './gtm.js'
import Decimal from 'decimal.js'
import { formatPrice } from '@commerce/product/use-price'

import useGradientGiftsCampaign from '@components/product/ProductSidebar/useGradientGiftsCampaign'
import SummerGiveawayVariants from '@components/product/SummerGiveawayVariants/SummerGiveawayVariants'
const ProductFreeGift = dynamic(() =>
  import('components/product/ProductFreeGift')
)

import s from './ProductCardPop.module.css'

const ProductQuantity = dynamic(() => import('../ProductQuantity'))
const Klarna = dynamic(() => import('../Klarna'))

const ProductCardPop = ({
  autoOpenSidebar = true,
  product,
  setOpenProductPop,
  metafields = {},
  coupons = [],
  text = {},
  pageHandle = '',
  sku,
  page_group = '',
  isRecommends = false,
  // summerGiveawayLines,
  closeDialog = false,
  gtmTitle = '',
  boaData = {},
  popButton = [],
  closeLoginPop = false,
  setCloseLoginPop = () => {},
  showGiftBoxInit = false,
  showPropertyGiftBox = false,
  priceDrop = false,
  customAttributes = [],
}) => {
  const {
    infos = {},
    preRender,
    productInfos,
    shopCommon,
    shopPreRender = {},
    shop_filters_pair,
    discounts,
    summerCampaignDiscount,
    thirdparty,
  } = metafields
  const [showGiftBox, setshowGiftBox] = useState(showGiftBoxInit)
  const [loading, setLoading] = useState(false)
  const [coupon, setCoupon] = useState()
  const [sales, setSales] = useState(false)
  const router = useRouter()
  const { shop } = useCommerce()

  const {
    combinationImg,
    combinationOpt,
    pageType,
    setCombinationImg,
    setSwiperIndex,
    openSidebar,
    setSalePrice,
    setOriginalPrice,
    couponInListing: couponData,
  } = useUI()
  const addItem = useAddItem()
  const codeApply = useCodeApply()
  const [selectedOptions, setSelectedOptions] = useInitializedOptionsState(
    product,
    sku
  )
  const variant = useVariant(product, selectedOptions)
  const variantInfos = variant?.metafields?.infos

  const activeNotify = variantInfos?.activeNotify || productInfos?.activeNotify

  const description =
    variantInfos?.description ||
    productInfos?.description ||
    product.descriptionHtml ||
    product.description

  const sliderImages = useMemo(() => {
    if (combinationImg && combinationImg?.length > 1) {
      return combinationImg?.map((item) => ({ url: item }))
    }
    return variant?.metafields?.images?.length > 0
      ? variant?.metafields?.images
      : product?.images
  }, [combinationImg])

  const orderLimits = shop?.orderLimits
  const orderLimit = orderLimits?.limits?.[variant?.sku]
  const [quantity, setQuantity] = useState(
    orderLimit?.min !== undefined ? orderLimit?.min : 1
  )
  const tags = product.tags?.find(
    (tag) => tag in (shopCommon?.tagToDescription || {})
  )
  const tag = shopCommon?.tagToDescription?.[tags]

  const ShowKlarna = useMemo(
    () => commerceEnabled('showKlarna', router?.locale),
    [router?.locale]
  )

  useEffect(() => {
    viewItemGTM({
      product: product,
      variant: variant,
      pageHandle: pageHandle,
    })
  }, [product])

  const { price } = usePrice({
    amount: Number(variant.price),
    currencyCode: product.price.currencyCode || product?.currencyCode,
  })
  const { price: sale_price } = usePrice({
    amount: priceDrop ? product?.price : Number(coupon?.variant_price4wscode),
    currencyCode: product.price.currencyCode || product?.currencyCode,
  })

  useEffect(() => {
    const coupon =
      coupons?.find(
        (i) => i.sku === variant.sku && i.handle === product.handle
      ) || {}
    setCoupon(coupon)
    // 检查是否有价格下降和产品是否有折扣值
    if (priceDrop && product?.discountValue) {
      // 格式化折扣值
      let discountValue = formatPrice({
        amount: product?.discountValue, // 折扣金额
        locale: router?.locale, // 本地化设置
        currencyCode: product?.currencyCode, // 货币代码
        maximumFractionDigits: 0, // 最大小数位数
      })
      // 设置销售信息并返回
      return setSales(`${discountValue} ${discounts?.off}`)
    }
    if (coupon && coupon.value_type === 'fixed_amount') {
      return setSales(`${coupon.value_style} ${discounts?.off}`)
    }
    if (coupon && coupon.value_type === 'percentage') {
      return setSales(`${Math.abs(parseInt(coupon.value))}% ${discounts?.off}`)
    }
    return setSales(null)
  }, [variant])

  const { variants: summerGiveawayVariants, lines: summerGiveawayLines } =
    useGradientGiftsCampaign({
      product,
      variant,
      coupons,
      campaign: summerCampaignDiscount,
      quantity,
    })

  const [copyCode, setCopyCode] = useState(false)
  const copyCouponCode = () => {
    setCopyCode(true)
    clipboard(coupon?.title)
    const copyTimer = setTimeout(() => {
      setCopyCode(false)
      clearTimeout(copyTimer)
    }, 2000)
  }

  const updateQuery = () => {
    const query = getQuery()
    delete query.variant
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${serializeObject(query)}${location.hash}`
    )
  }

  // 买赠数据
  const memberBenefits = variant?.metafields?.memberBenefits
  const [initTotalPrice, initTotalBasePrice] = useMemo(() => {
    const totalPrice = new Decimal(
      couponData?.variant_price4wscode || variant?.price
    ).times(quantity)
    const totalBasePrice = new Decimal(
      variant?.listPrice || variant?.price
    ).times(quantity)
    return [totalPrice.toNumber(), totalBasePrice.toNumber()]
  }, [couponData, variant])

  const [freeGiftProducts, setFreeGiftProducts] = useState([])
  const [tradeGift, setTradeGift] = useState([])
  const [customAttributeItem, setCustomAttributeItem] = useState()

  const setMemberBenefits = (item) => {
    setCustomAttributeItem(item)
  }

  const setFreeGiftProductsHandle = (gifts) => {
    setFreeGiftProducts(gifts)
  }
  useEffect(() => {
    if (!variant?.metafields?.freeGift) {
      setFreeGiftProducts([])
    }
    if (!memberBenefits || !memberBenefits?.property || showPropertyGiftBox) {
      setshowGiftBox(true)
    } else {
      setshowGiftBox(false)
    }
  }, [variant])

  useEffect(() => {
    if (!variant?.metafields?.tradeGift) {
      setTradeGift([])
      setSalePrice(0)
      setOriginalPrice(0)
    }
  }, [variant?.metafields?.tradeGift])

  const addToCart = async () => {
    setLoading(true)

    let lineItems = [
      {
        productId: String(product?.id),
        variantId: String(variant?.id || product.variants?.[0]?.id),
        quantity: quantity || 1,
        ...(customAttributeItem && {
          customAttributes: customAttributeItem,
        }),
      },
    ]

    freeGiftProducts?.length > 0 &&
      lineItems.push(
        ...freeGiftProducts?.map((item) => ({
          variantId: item?.variantId,
          quantity: item?.quantity,
          ...(customAttributeItem && {
            customAttributes: customAttributeItem,
          }),
        }))
      )
    tradeGift?.length > 0 &&
      lineItems.push(
        ...tradeGift?.map((item) => ({
          variantId: item?.variantId,
          quantity: item?.quantity,
          ...(item?.customAttributes && {
            customAttributes: item?.customAttributeItem,
          }),
        }))
      )
    try {
      await addItem(lineItems)
      if (coupon?.title) {
        codeApply({ discountCode: coupon?.title })
      }
      autoOpenSidebar && openSidebar()
      if (closeDialog) {
        setOpenProductPop(false)
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
    const oVariant = variant || product?.variants?.[0]
    if (coupon?.title) {
      oVariant.variant_price4wscode = coupon.variant_price4wscode
    }
    if (isRecommends) {
      listAddToCartGTM({
        product,
        variant: oVariant,
        quantity,
        coupon,
        page_group,
      })
    } else {
      addToCartGTM(product, oVariant, quantity, router?.locale, page_group)
    }
  }
  const buyNow = async () => {
    setLoading(true)
    let lineItems = summerGiveawayLines?.length > 0 ? summerGiveawayLines : []
    lineItems?.push({
      variantId: String(variant?.id || product.variants?.[0]?.id),
      quantity: quantity || 1,
      ...((customAttributes?.length > 0 || customAttributeItem) && {
        customAttributes:
          customAttributes?.length > 0 ? customAttributes : customAttributeItem,
      }),
    })
    freeGiftProducts?.length > 0 &&
      lineItems.push(
        ...freeGiftProducts?.map((item) => ({
          variantId: item?.variantId,
          quantity: item?.quantity,
          ...(customAttributeItem && {
            customAttributes: customAttributeItem,
          }),
        }))
      )
    tradeGift?.length > 0 &&
      lineItems.push(
        ...tradeGift?.map((item) => ({
          variantId: item?.variantId,
          quantity: item?.quantity,
          ...(item?.customAttributes && {
            customAttributes: item?.customAttributes,
          }),
        }))
      )

    const checkout = await createCheckout({
      locale: router?.locale,
      lineItems,
      discountCodes: coupon?.title && !priceDrop ? [coupon?.title] : [],
    })

    if (checkout?.webUrl) {
      const oVariant = variant || product?.variants?.[0]
      if (coupon?.title) {
        oVariant.variant_price4wscode = coupon.variant_price4wscode
      }
      if (isRecommends) {
        listBuyNowGTM({
          product,
          variant: oVariant,
          quantity,
          coupon,
          page_group,
        })
      } else {
        buyNowGTM(product, oVariant, quantity, page_group)
      }
    }
    setLoading(false)
    if (checkout?.webUrl) {
      router.push(checkout?.webUrl)
    }
  }

  const handleBuried = (product, variant) => {
    if (typeof fbq !== 'undefined') {
      fbq('track', 'BuyAtAmazon', {
        content_ids: [].concat(variant?.sku),
      })
      boaData?.extraAmzTrak && fbq('trackCustom', boaData?.extraAmzTrak)
    }
    pageTwttr({ event: 'o8e4y', tw_sale_amount: 0, tw_order_quantity: 0 })
    if (window?.ttq && thirdparty?.show_TTQTrack) {
      ttq?.track('Buy on Amazon', {
        contents: [
          {
            content_id: variant?.sku || '',
            content_type: product?.productType || '',
            content_name: product?.title || '',
          },
        ],
        value: variant?.price || 0,
        currency: product?.price?.currencyCode || '',
      })
    }
  }

  if (!product) return

  return (
    <div
      className={cn(
        s.ProductPop,
        'fixed inset-0 bottom-0 left-0 right-0 top-0 z-[1000] bg-black bg-opacity-60'
      )}
    >
      <div
        className={cn(
          s.ProductPopBox,
          'absolute left-[50%] top-[50%] h-[70vh] max-h-[860px] w-[80vw] max-w-[1280px] -translate-x-1/2 -translate-y-1/2 rounded-xl bg-[#fff] md:bottom-0 md:top-auto md:h-[85vh] md:w-[100vw] md:-translate-y-0 md-l:w-[750px]'
        )}
      >
        <Close
          className="absolute right-[16px] top-[16px] z-[2] h-[25px] w-[25px] cursor-pointer fill-[#999]"
          onClick={() => {
            setOpenProductPop(false)
            updateQuery()
          }}
        />
        <div className={s.productBox}>
          <div className={s.imageBox}>
            <ProductSwiper
              variant={variant}
              preRender={preRender}
              sliderImages={sliderImages}
              className="mx-auto h-[48vh] max-h-[450px] min-h-[300px] w-full md:h-[100vw] md:!pb-[70px]"
              subClassName="!h-[120px] md:hidden"
              showPagination={true}
            ></ProductSwiper>
            {sales && (
              <div className="absolute left-[24px] top-0 z-[1] h-[60px] w-[60px] text-base font-bold text-white md:left-0 md:h-[44px]  md:w-[50px]">
                <p className="relative left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 break-words px-1 py-[2px] text-center leading-[1.2] md:text-[12px]">
                  {sales}
                </p>
                {text?.saleBg && (
                  <Picture
                    alt="sales"
                    className="absolute left-0 top-0 w-full object-fill"
                    source={text?.saleBg}
                    src={text?.saleBg}
                  />
                )}
              </div>
            )}
          </div>
          <div className={s.infoBox}>
            <div className={cn(s.tagName)}>
              <div className="min-h-[22px]">
                {tag?.link?.to ? (
                  <div className="flex items-center justify-between pb-2 pt-2 text-[0.8rem] leading-normal md:text-[3vw]">
                    <div className="flex items-center">
                      {tag?.label && (
                        <Text
                          html={tag?.label}
                          className={cn(
                            'mr-2 flex-shrink-0 rounded border border-[#EF8851] px-1 text-[#EF8851]',
                            s.tagItem
                          )}
                          variant="paragraph"
                        />
                      )}
                      {tag?.description && (
                        <Text
                          html={tag?.description}
                          className="h-fit"
                          variant="paragraph"
                        />
                      )}
                    </div>
                    <a
                      className="text-[#EF8851]"
                      href={`/${router?.locale}${tag?.link?.to}`}
                      onClick={() =>
                        pageGTMEvent({
                          event: 'uaEvent',
                          eventCategory: 'Product Detail Page_' + variant?.sku,
                          eventAction: 'special_offer',
                          eventLabel: 'learn_more',
                        })
                      }
                    >
                      {tag?.link?.label}
                    </a>
                  </div>
                ) : (
                  tag &&
                  pageType !== 'hes' && (
                    <Text
                      variant="paragraph"
                      className={cn(
                        'mr-2 w-fit flex-shrink-0 rounded border border-[#EF8851] px-1 text-[0.8rem] leading-normal text-[#EF8851] md:text-[3vw]',
                        s.tagItem
                      )}
                      html={tag || ''}
                    />
                  )
                )}
              </div>

              <a
                className={cn(s.productTitleBox, {
                  [s.priceDropTitleBox]: !priceDrop,
                })}
                {...(!priceDrop && {
                  href: `${
                    router?.locale === 'us' ? '' : `/${router?.locale}`
                  }/products/${product.handle}?variant=${atobID(
                    variant.id
                  )}&ref=${gtmTitle || pageHandle}`,
                })}
              >
                <Text
                  variant="heading"
                  className={cn(s.productTitle)}
                  html={variantInfos?.name || product.name}
                />
                {productInfos?.new && (
                  <span className={s.new}>
                    {variantInfos?.new || productInfos?.new}
                  </span>
                )}
              </a>
              {variantInfos?.short_title && (
                <Text
                  variant="heading"
                  className="my-3 text-base"
                  html={variantInfos?.short_title}
                />
              )}

              <ProductPrice
                styles={s}
                className={s.productPrice}
                price={sales ? sale_price : price}
                basePrice={sales && price}
                availableForSale={variant?.availableForSale}
                settings={shopCommon}
                comingSoon={
                  variantInfos?.comingSoon || productInfos?.comingSoon
                }
              />

              {ShowKlarna &&
                product.variants.map((v, index) => {
                  return (
                    v.id === variant?.id && (
                      <Klarna
                        key={index}
                        price={v.price}
                        settings={metafields.klarnaSettings}
                      />
                    )
                  )
                })}
              {!preRender?.hideAffirm && !shopPreRender?.hideAffirm && (
                <Affirm variant={variant} />
              )}

              {!priceDrop && (
                <div className="h-[24px]">
                  {sales && (
                    <div className={cn(s.couponBox)}>
                      <span>{metafields?.discounts?.code}: </span>
                      <span>{coupon?.title}</span>
                      <div className={s.copy}>
                        <button
                          className={cn(s.copyBtn, {
                            [s.copyActive]: copyCode,
                          })}
                          onClick={copyCouponCode}
                        >
                          {copyCode
                            ? metafields?.discounts?.copied
                            : metafields?.discounts?.copy}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <LearnMore
              settings={shopCommon}
              className={s.pxLearnMore}
              product={product}
              hideLearnMore={
                preRender?.hideLearnMore || shopPreRender?.hideLearnMore
              }
              s={s}
            >
              {(variantInfos?.subtitle || productInfos?.subtitle) && (
                <Text
                  className={cn(s.descText)}
                  html={variantInfos?.subtitle || productInfos?.subtitle}
                />
              )}
              {description && (
                <Text className={cn(s.descText)} html={description} />
              )}
            </LearnMore>

            {!priceDrop && (
              <ProductOptions
                cnStyles={s.ProductOptions}
                productImage={product.images?.[0]?.url}
                product={product}
                options={product.options}
                selectedOptions={selectedOptions}
                variantId={variant?.id}
                setSelectedOptions={(obj, current, variant) => {
                  setSelectedOptions(obj, current, variant)
                  if (combinationImg?.length > 1) {
                    const imgs = [...combinationImg]
                    imgs[0] = variant?.image?.url
                    setCombinationImg(imgs)
                    setSwiperIndex(1)
                  }
                }}
                s={s}
              />
            )}

            {preRender?.hideQuantity || combinationOpt?.length > 0 ? null : (
              <div className={cn(s.commonInfos, s.quantityBox)}>
                <div className={s.infoTitle}>{infos?.quantity_txt}</div>
                <ProductQuantity
                  className={s.ProductQuantity}
                  pId={variant?.sku || product.variants?.[0]?.sku}
                  settings={variant?.metafields}
                  value={Number(quantity)}
                  max={variant?.quantityAvailable}
                  currentlyNotInStock={variant?.currentlyNotInStock}
                  availableForSale={variant?.availableForSale}
                  increase={(num) => {
                    setQuantity(quantity + num)
                  }}
                  decrease={(num) => {
                    setQuantity(quantity + num)
                  }}
                  handleChange={(val) => {
                    setQuantity(val)
                  }}
                  s={s}
                  shopCommon={shopCommon}
                />
              </div>
            )}

            {summerGiveawayVariants?.length > 0 &&
              !preRender?.hideSummerGiveaway && (
                <SummerGiveawayVariants
                  variants={summerGiveawayVariants}
                  data={{
                    ...(shopCommon || {}),
                    ...(summerCampaignDiscount || {}),
                  }}
                  cns={{
                    freeGiftContainer:
                      '!border-b-0 !border-t !border-[#e5e5e5] !pt-[20px]',
                  }}
                />
              )}

            {/* 买赠需要做特殊展示或隐藏处理 */}
            {showGiftBox && variant?.metafields?.freeGift && !priceDrop && (
              <ProductFreeGift
                metafields={metafields}
                variant={variant}
                freeGiftProducts={freeGiftProducts}
                setFreeGiftProductsHandle={setFreeGiftProductsHandle}
                setMemberBenefits={setMemberBenefits}
                // setShowDiscount={setShowDiscount}
                closeLoginPop={closeLoginPop}
                setCloseLoginPop={setCloseLoginPop}
                allShowGift={true}
              />
            )}
            {/* 换购无特殊逻辑 */}
            {showGiftBoxInit && variant?.metafields?.tradeGift && (
              <ProductFreeGift
                freeGiftProducts={freeGiftProducts}
                key={'isTradeGift' + 1}
                isTradeGift
                metafields={metafields}
                variant={variant}
                totalPrice={initTotalPrice}
                totalBasePrice={initTotalBasePrice}
                // s={styles}
                setFreeGiftProductsHandle={() => {}}
                // setMemberBenefits={setMemberBenefits}
                // setShowDiscount={setShowDiscount}
                closeLoginPop={closeLoginPop}
                setCloseLoginPop={setCloseLoginPop}
                giftProducts={[tradeGift, setTradeGift]}
              />
            )}
          </div>
        </div>

        <div className={s.buyBox}>
          <div className={cn(s.buyPrice, 'mr-[20px] text-right')}>
            <ProductPrice
              styles={s}
              className={s.productPrice}
              price={sales ? sale_price : price}
              basePrice={sales && price}
              availableForSale={variant?.availableForSale}
              settings={shopCommon}
              comingSoon={variantInfos?.comingSoon || productInfos?.comingSoon}
            />
          </div>
          <div className={cn(s.buyBtnBox, 'buyBtn')}>
            {variant?.availableForSale ? (
              (boaData?.popButton?.length > 0
                ? boaData?.popButton
                : popButton.length
                ? popButton
                : shop_filters_pair?.filter_txt?.popButton
              )?.map((item, index) => {
                return item?.type === 'amazonLink' ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-[44px] min-w-[200px] cursor-pointer rounded-full bg-[#FF9A00] text-center text-[14px] font-[600] leading-[44px] text-[#fff]"
                    href={`${boaData?.amazonLink}?${getAmazonLinkParams(
                      boaData?.amazonLink?.split('/')?.[
                        boaData?.amazonLink?.split('/')?.length - 1
                      ],
                      boaData?.adCampaign,
                      router?.locale
                    )}`}
                    onClick={() => handleBuried(product, variant)}
                    dangerouslySetInnerHTML={{ __html: item?.txt }}
                  ></a>
                ) : (
                  item?.txt && (
                    <Button
                      key={index}
                      className={cn(
                        s.buyBtn,
                        item?.type === 'addToCart'
                          ? `${s.addToCart} addToCart`
                          : `${s.buyNow} buyNow`
                      )}
                      small
                      border={item?.type === 'addToCart'}
                      loading={loading}
                      onClick={(e) =>
                        item?.type === 'addToCart' ? addToCart(e) : buyNow(e)
                      }
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: item?.txt }}
                      ></span>
                    </Button>
                  )
                )
              })
            ) : (
              <OutStockNotify
                name={variantInfos?.name || product.name}
                sku={variant?.sku}
                metafields={metafields}
                shop={shop}
                locale={router?.locale}
                activeNotify={activeNotify}
                product={product}
                btnClass=" mr-2 h-12 w-56 rounded-3xl px-3 font-semibold leading-none md:h-10 md:w-32 md:text-[14px]"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCardPop
