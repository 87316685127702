import { useRouter } from 'next/router'
import { Picture, Text } from '@components/ui'
import { formatPrice } from '@commerce/product/use-price'
import s from './SummerGiveawayVariants.module.css'
import cn from 'classnames'

const SummerCampaignGiveawayProducts = ({ variants, data, cns = {} }) => {
  const { locale } = useRouter()

  const availableForSale = variants?.find?.(
    (variant) => variant?.availableForSale
  )
  if (!availableForSale) return

  return (
    <div className={cn(s.freeGiftContainer, cns?.freeGiftContainer)}>
      <Text className={s.title} html={data.listingDescription}></Text>

      <div className={s.giftList}>
        {variants?.map((variant, index) => {
          if (!variant?.availableForSale) return null
          return (
            <div className={s.giftItemWrap} key={index}>
              <div className={cn(s.giftItem)}>
                <Picture
                  alt={variant?.image?.altText}
                  className={
                    'mr-[16px] h-[60px] w-[60px] rounded-full [&_img]:h-full [&_img]:object-contain'
                  }
                  source={`${variant?.images?.[0]?.url || variant?.image?.url}`}
                />
                <div className={s.giftTxt}>
                  <Text
                    className={cn(
                      s.giftName,
                      variant?.description ? '!mb-[0px]' : ''
                    )}
                    html={variant?.name}
                  ></Text>
                  {variant?.discountDescription && (
                    <Text
                      className="my-[6px]"
                      html={variant?.discountDescription}
                    ></Text>
                  )}
                  <div className={s.giftPrice}>
                    <Text className={s.free} html={data.free}></Text>
                    <div className={s.price}>
                      {formatPrice({
                        amount: Number(variant?.price),
                        currencyCode: variant?.currencyCode || 'USD',
                        locale,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SummerCampaignGiveawayProducts
