import { useMemo, useEffect, useState } from 'react'
import { useCart } from 'lib/shopify/api/cart'
import { fuzzyEqual } from '@components/helper'
import useProduct from '@shopify/product/use-product'
import Decimal from 'decimal.js'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { getQuery } from '@lib/utils/cookies'

const useGradientGiftsCampaign = ({
  combineVariant,
  product,
  variant,
  coupons,
  campaign,
  quantity,
}) => {
  const { data } = useCart()
  const { locale } = useRouter()
  const [points_subscribe, set_points_subscribe] = useState(false)
  const [addUTMFreeItem, setAddUTMFreeItem] = useState(false)

  useEffect(() => {
    const query = getQuery()
    const utmCampaign = Cookies.get('utm_campaign') || query?.utm_campaign
    const result =
      campaign?.activityAvailableQuery &&
      utmCampaign?.includes(campaign?.activityAvailableQuery)
    setAddUTMFreeItem(result)
  }, [])

  useEffect(() => {
    if (locale === 'au') {
      const isPointsSubscribe = Cookies.get('points_subscribe')
      if (isPointsSubscribe) {
        set_points_subscribe(true)
      }
    }
  }, [data, locale])

  const [upgrade_multiple, upgrade_value] = useMemo(() => {
    let upgrade_multiple = 1
    let upgrade_value = 0
    if (points_subscribe) {
      upgrade_multiple = 1.2
      upgrade_value = 40
    }
    data?.lineItems?.forEach(({ customAttributes }) => {
      customAttributes?.forEach(({ key, value }) => {
        if (key === '_amount_upgrade_multiple') {
          upgrade_multiple = value
        }
        if (key === '_amount_upgrade_value') {
          upgrade_value = value
        }
      })
    })
    return [upgrade_multiple, upgrade_value, data?.lineItems]
  }, [data, points_subscribe])

  const breakpoints = useMemo(() => {
    return campaign?.breakpoints?.map((item) => {
      return {
        breakpoint: new Decimal(item?.breakpoint)
          .minus(new Decimal(upgrade_value))
          .dividedBy(new Decimal(upgrade_multiple))
          .toFixed(2, Decimal.ROUND_DOWN),
        giveawayProducts: item?.giveawayProducts,
        description: item?.description,
      }
    })
  }, [campaign?.breakpoints, upgrade_multiple, upgrade_value])

  const isSummerProduct = product?.tags?.find((tag) =>
    campaign?.includeTags?.includes(tag.trim())
  )
  let summerLevel
  if (isSummerProduct) {
    const sortedLevel = [...(breakpoints || [])]?.sort(
      (a, b) => b.breakpoint - a.breakpoint
    )
    // console.log('coupons:', Math.abs(coupons[variant?.sku]?.[0]?.value))
    let price = campaign?.useTotalAmount
      ? coupons?.[variant?.sku]?.[0]
        ? new Decimal(
            coupons?.[variant?.sku]?.[0]?.variant_price4wscode || 0
          ).times(quantity || 1)
        : coupons?.length
        ? coupons?.[0].variant_price4wscode
        : new Decimal(variant?.price || 0).times(quantity || 1)
      : new Decimal(variant?.price)

    if (combineVariant?.length > 0) {
      const totalOriginal = combineVariant?.reduce((prev, cur) => {
        return new Decimal(prev).plus(
          new Decimal(cur?.variant?.price || 0).times(
            new Decimal(cur?.quantity || 1)
          )
        )
      }, 0)

      const totalDiscount = combineVariant?.reduce((prev, cur) => {
        return new Decimal(prev).plus(
          new Decimal(
            cur?.coupon?.variant_price4wscode || cur?.variant?.price || 0
          ).times(new Decimal(cur?.quantity || 1))
        )
      }, 0)
      price = campaign?.useTotalAmount ? totalDiscount : totalOriginal
    }

    // 当前购物车中所有 pps 商品可以达到的满减阶梯
    const levelIndex = sortedLevel?.findIndex(
      (discount) => Number(price) >= Number(discount.breakpoint)
    )
    summerLevel = sortedLevel[levelIndex]
    // console.log('summerLevel:', summerLevel)
  }

  const { data: summerResult } = useProduct({
    handles: summerLevel?.giveawayProducts
      ?.map((product) => product.handle)
      ?.join(','),
  })

  let lines = []
  let variants = []

  if (!campaign?.activityAvailableQuery || addUTMFreeItem) {
    const customAttributes = [
      { key: '_giveaway', value: '_giveaway' },
      {
        key: '_giveaway_summerCampaignDiscount',
        value: '_giveaway_summerCampaignDiscount',
      },
      ...(points_subscribe
        ? [
            {
              key: '_amount_upgrade_multiple',
              value: '1.2',
            },
            {
              key: '_amount_upgrade_value',
              value: '40',
            },
          ]
        : []),
    ]
    if (addUTMFreeItem) {
      customAttributes.push({
        key: campaign?.activityQroperty,
        value: campaign?.activityQroperty,
      })
    }
    variants = summerLevel?.giveawayProducts?.map((product) => {
      const productResult = summerResult?.products?.find((p) =>
        fuzzyEqual(p.handle, product?.handle)
      )
      // console.log('productResult:', productResult)
      const variant = productResult?.variants?.find((variant) =>
        fuzzyEqual(variant.sku, product?.sku)
      )
      return {
        ...variant,
        name: productResult?.name,
        currencyCode: productResult?.price?.currencyCode,
        discountDescription: summerLevel?.description,
      }
    })
    lines = variants?.map((variant) => ({
      variantId: variant?.id,
      quantity: 1,
      customAttributes,
    }))
  }

  // console.log('lines:', product.price, lines)

  return { variants, lines }
}

export default useGradientGiftsCampaign
