import { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'
import { getAdCookie } from '@lib/utils/cookies'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { Button } from '@components/ui'
import Icon from '@components/icons'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { regExp } from '@lib/utils/regExp'
import fetcher from '@lib/fetcher'
import { useCommerce } from '@commerce/index'
import { sha256 } from '@lib/utils/tools'

import s from './OutStockNotify.module.css'

const OutStockNotify = ({
  className,
  sku,
  name,
  metafields,
  locale,
  activeNotify,
  product,
  btnClass,
  border,
}) => {
  let timer = null
  const { copywriting, shopCommon } = metafields
  const { shop } = useCommerce()
  const [showDialog, setShowDialog] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [policy, setPolicy] = useState(false)
  const [success, showSuccess] = useState(false)
  const [formError, setFormError] = useState('')
  const [email, setEmail] = useState('')
  const notify = activeNotify
    ? copywriting?.activeNotify
    : copywriting?.outStockNotify
  const showFormError = ({ type, error }) => {
    setFormError(error || copywriting[type] || 'unknown error')
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setFormError('')
    }, 5000)
  }

  const handleSubmit = async () => {
    if (!email) {
      return showFormError({ type: 'fillEmail' })
    }
    if (!regExp.email.test(email)) {
      return showFormError({ type: 'errEmail' })
    }
    if (activeNotify) {
      handleActivities()
    } else {
      handleSubscribe()
    }
  }
  const handleActivities = async () => {
    const { register_source } = getAdCookie()
    let url = '/api/multipass/rainbowbridge/activities'
    const result = await fetcher({
      locale,
      url,
      action: 'activity',
      needRecaptcha: true,
      method: 'POST',
      body: {
        email,
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: 'order_WS_0701',
      },
    })

    if (!result.errors) {
      setEmail('')
      showSuccess(true)
      if (policy) {
        pageGTMEvent({
          subscribe_hashed_email: email ? sha256(email) : '',
        })
        pageGTMEvent({
          event: 'uaEvent',
          eventCategory: 'subscribe',
          eventAction: 'bottom_sticky_pop',
          eventLabel: 'Product Detail Page_' + sku,
        })
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'subscribe',
          event_parameters: {
            page_group: 'Product Detail Page_' + sku,
            positon: 'pop',
          },
        })
      }
    } else {
      const error =
        result?.status === 422
          ? copywriting?.registeredError || result.errors
          : result.errors
      showFormError({ error: error })
    }
  }

  const handleSubscribe = async () => {
    const result = await fetcher({
      locale,
      action: 'outStockNotify',
      url: '/api/multipass/shopifyservices/product_arrival_notices',
      method: 'POST',
      body: {
        email,
        sku,
        shop: shop?.primaryDomain?.host,
      },
    })
    if (!result.errors) {
      setEmail('')
      showSuccess(true)
      pageGTMEvent({
        subscribe_hashed_email: email ? sha256(email) : '',
      })
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'notify_me',
        eventAction: sku,
        eventLabel: policy ? 'consent' : 'not_consent',
        eventValue: '',
      })
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'subscribe',
        event_parameters: {
          page_group: 'Product Detail Page_' + sku,
          positon: 'pop',
        },
      })
    } else {
      const error =
        result?.status === 422
          ? copywriting?.registeredError || result.errors
          : result.errors
      showFormError({ error: error })
    }

    if (policy) {
      fetcher({
        locale,
        action: 'outStockNotify_subscribe_emails',
        url: '/api/multipass/subscribe/subscribe_emails',
        method: 'POST',
        body: {
          ...getAdCookie,
          email,
        },
      })
    }
  }

  const rootClassName = cn(s.root, className)

  return (
    <div className={rootClassName}>
      <Button
        variant="primary"
        className={btnClass}
        onClick={() => {
          pageGTMEvent({
            event: 'uaEvent',
            eventCategory: notify?.btnText || 'Notify Me',
            eventAction: 'bottom_sticky',
            eventLabel: 'Product Detail Page_' + sku,
          })
          pageGTMEvent({
            event: 'ga4Event',
            event_name: 'bottom_function',
            event_parameters: {
              page_group: 'Product Detail Page_' + sku,
              action: notify?.btnText || 'Notify Me',
            },
          })
          setShowDialog(!showDialog)
          showSuccess(false)
        }}
        border={border}
      >
        {notify?.btnText || 'Notify Me'}
      </Button>
      <div className={`${s.dialog} ${!showDialog && 'hidden'}`}>
        <div className={s.box}>
          <button
            className={`${s.close} fixed right-5 top-5 w-10 text-black`}
            onClick={() => setShowDialog(false)}
          >
            <Icon iconKey="close" width="30"></Icon>
          </button>

          {success ? (
            <div className={s.content}>
              <p className="min-w-max px-4 pt-4 text-center text-xl font-normal text-black">
                {notify?.success || 'Success!'}
              </p>
            </div>
          ) : (
            <div className={s.content}>
              <h1 className="mb-4 text-3xl	font-bold text-black">
                {notify?.title || 'Email me when available'}
              </h1>
              <p className="mb-4 text-base font-normal">
                {notify?.subTitle ||
                  'Leave your email address and we will notify you when the product is back in stock.'}
              </p>
              <p
                className="mb-2 text-lg font-medium"
                dangerouslySetInnerHTML={{ __html: name }}
              />
              <div className={s.emailInput}>
                <input
                  type="text"
                  name="email"
                  placeholder="you@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value?.trim())}
                />
                <div className="absolute top-2.5 right-2 h-5 w-5">
                  <button
                    className={s.tipIcon}
                    onClick={() => setShowInfo(!showInfo)}
                  >
                    <InformationCircleIcon className="h-5 w-5" />
                  </button>
                  <button
                    className={`${s.toast} ${!showInfo && 'hidden'}`}
                    onClick={() => setShowInfo(!showInfo)}
                  >
                    {notify?.tipText ||
                      `Your email address will be only used to notify you of stock information. We don&apos;t share your information with anybody else.`}
                  </button>
                </div>
              </div>

              <Button className={s.submit} onClick={handleSubmit}>
                {notify?.submitBtnText || 'Email me when available'}
              </Button>
              <p className={s.policy}>
                <input
                  className="mr-1"
                  type="checkbox"
                  name="policy"
                  checked={policy}
                  onChange={(e) => setPolicy(e.target.checked)}
                />
                <span className="text-base font-thin">
                  {notify?.optional ||
                    '(Optional) Join our Anker email list to get special offers and more.'}
                </span>
              </p>

              {formError && <p className={s.error}>{formError}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OutStockNotify
