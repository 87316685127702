import { pageGTMEvent, GTMtagsToCollection } from '@lib/utils/thirdparty'
import setAwsGaData from '@lib/apis/set-aws-ga-data'
import { atobID } from '@lib/utils/tools'
import { languageCountry } from '@commerce/product/use-price'
import UAParser from 'ua-parser-js'

export const addToCartGTM = (
  product,
  variant,
  quantity,
  locale,
  page_group
) => {
  pageGTMEvent({
    event: 'eeEvent',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'Added Product', // 如果是右上角加购功能, 换成Added Product   用这个字段来识别是 Buy Now 按钮埋点​
    eventLabel: variant?.sku,
    nonInteraction: false,
    ecommerce: {
      currencyCode: product?.price?.currencyCode, // 填写对应货币 USD EUR...​
      add: {
        products: [
          {
            name: product.name, // 对应下方截图的product name​
            id: variant?.sku, // 对应下方截图的product id (注意大小写)​
            category: '', // 留空​
            categoryName: product.productType, // 对应下方截图的product cat...​
            brand: product.vendor, // 对应下方截图的product brand​
            variant: variant?.name, // 对应下方截图的product variant​
            price: variant?.price, // 对应下方截图的product price​
            quantity: quantity || 1, // 对应下方截图的product quantity​
          },
        ],
      },
    },
  })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'add_to_cart',
    event_parameters: {
      page_group: `Activity Page_${variant?.sku}`,
      position: `${page_group || 'Product Detail Page'}`,
      currency: product?.price?.currencyCode,
      value: variant?.variant_price4wscode || variant?.price,
      items: [
        {
          item_id: variant?.sku,
          item_name: product.name,
          item_brand: product.vendor,
          item_category: '',
          item_variant: variant?.name,
          price: variant?.price,
          quantity: quantity || 1,
        },
      ],
    },
  })
  typeof fbq !== 'undefined' &&
    fbq('track', 'AddToCart', {
      value: variant?.price,
      num_items: quantity,
      currency: product?.price?.currencyCode,
      content_type: 'product_group',
      content_ids: atobID(variant?.id),
      content_category: GTMtagsToCollection(variant?.tags || product?.tags),
    })

  const parser = new UAParser()
  const userAgent = parser.getResult()

  window?.ga(function (tracker) {
    const clientId = tracker.get('clientId')
    setAwsGaData({
      locale: locale,
      eventList: [
        {
          eventType: 'add_to_cart',
          itemId: variant.sku,
          properties: {
            device_type: userAgent?.device?.type || userAgent?.os?.name,
            browser_type: userAgent?.browser?.name,
            country: languageCountry(locale),
          },
          sentAt: Date?.now()?.toString()?.substring(0, 10),
        },
      ],
      userId: clientId,
    })
  })
}

export const listAddToCartGTM = ({
  product,
  variant,
  quantity,
  coupon,
  page_group,
  itemListName,
}) => {
  pageGTMEvent({
    event: 'eeEvent',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'Added Product', // 如果是加购功能, 换成Added Product
    eventLabel: variant?.sku,
    nonInteraction: false,
    ecommerce: {
      currencyCode: product?.price?.currencyCode, // 填写对应货币 USD EUR...
      add: {
        products: [
          {
            id: variant?.sku, //产品SKU
            name: product?.name,
            brand: product?.vendor,
            category: '',
            categoryName: product?.productType,
            variant: variant?.name,
            price: variant?.price,
            quantity: quantity || 1,
          },
        ],
      },
    },
  })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'add_to_cart',
    event_parameters: {
      page_group: `${page_group || 'Product Detail Page'}_${variant?.sku}`,
      position: `${page_group || 'Product Detail Page'}`,
      currencyCode: product?.price?.currencyCode,
      value: coupon?.variant_price4wscode || variant?.price,
      coupon: coupon?.title,
      item_list_name: itemListName || 'Home_Page_Bundle', //特别注意：该变量传值以活动埋点文档要求为准，如活动埋点文档中未写定传值，则传该模块的小标题
      items: [
        {
          item_id: variant?.sku, //产品SKU
          item_name: product?.name,
          item_brand: product?.vendor,
          item_category: '',
          item_variant: variant?.name,
          price: variant?.price,
          quantity: quantity || 1,
          coupon: coupon?.title || '',
          discount: coupon?.variant_price4wscode || 0,
        },
      ],
    },
  })
}

// start_ai_generated
export const listBuyNowGTM = ({
  product,
  variant,
  quantity,
  coupon,
  page_group,
  itemListName,
}) => {
  pageGTMEvent({
    event: 'eeEvent',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'BuyNow', // 如果是加购功能, 换成Added Product
    eventLabel: variant?.sku,
    nonInteraction: false,
    ecommerce: {
      currencyCode: product?.price?.currencyCode, // 填写对应货币 USD EUR...
      add: {
        products: [
          {
            id: variant?.sku, //产品SKU
            name: product?.name,
            brand: product?.vendor,
            category: '',
            categoryName: product?.productType,
            variant: variant?.name,
            price: variant?.price,
            quantity: quantity || 1,
          },
        ],
      },
    },
  })
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'begin_checkout',
    event_parameters: {
      page_group: `${page_group || 'Product Detail Page'}_${variant?.sku}`,
      position: `${page_group || 'Product Detail Page'}`,
      currencyCode: product?.price?.currencyCode,
      value: coupon?.variant_price4wscode || variant?.price,
      coupon: coupon?.title,
      item_list_name: itemListName || 'Home_Page_Bundle', //特别注意：该变量传值以活动埋点文档要求为准，如活动埋点文档中未写定传值，则传该模块的小标题
      items: [
        {
          item_id: variant?.sku, //产品SKU
          item_name: product?.name,
          item_brand: product?.vendor,
          item_category: '',
          item_variant: variant?.name,
          price: variant?.price,
          quantity: quantity || 1,
          coupon: coupon?.title || '',
          discount: coupon?.variant_price4wscode || 0,
        },
      ],
    },
  })
}
// end_ai_generated

export const buyNowGTM = (product, variant, quantity, page_group) => {
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'begin_checkout',
    event_parameters: {
      page_group: `Activity Page_${variant?.sku}`,
      position: `${page_group || 'Product Detail Page'}`,
      currency: product?.price?.currencyCode,
      value: variant?.variant_price4wscode || variant?.price,
      items: [
        {
          item_id: variant?.sku,
          item_name: product.name,
          item_brand: product.vendor,
          item_category: '',
          item_variant: variant?.name,
          price: variant?.price,
          quantity: quantity || 1,
        },
      ],
    },
  })
  pageGTMEvent({
    event: 'eeEvent',
    eventCategory: 'EnhancedEcommerce',
    eventAction: 'BuyNow', // 如果是右上角加购功能, 换成Added Product   用这个字段来识别是 Buy Now 按钮埋点​
    eventLabel: variant?.sku,
    nonInteraction: false,
    ecommerce: {
      currencyCode: product?.price?.currencyCode, // 填写对应货币 USD EUR...​
      add: {
        products: [
          {
            name: product.name, // 对应下方截图的product name​
            id: variant?.sku, // 对应下方截图的product id (注意大小写)​
            category: '', // 留空​
            categoryName: product?.productType || '', // 对应下方截图的product cat...​
            brand: product.vendor, // 对应下方截图的product brand​
            variant: variant?.name, // 对应下方截图的product variant​
            price: variant?.price, // 对应下方截图的product price​
            quantity: quantity || 1, // 对应下方截图的product quantity​
          },
        ],
      },
    },
  })
  typeof fbq !== 'undefined' &&
    fbq('track', 'InitiateCheckout', {
      value: variant?.price,
      num_items: quantity,
      currency: product?.price?.currencyCode,
      content_type: 'product_group',
      content_ids: atobID(variant?.id), // 产品的varaint id
      content_category: GTMtagsToCollection(variant?.tags || product?.tags),
    })
}

export const viewItemGTM = ({ product, variant, pageHandle }) => {
  pageGTMEvent({
    event: 'view_item',
    page_group: 'Activity Page_' + pageHandle,
    ecommerce: {
      currency: product?.price?.currencyCode,
      value: variant?.variant_price4wscode || variant?.price,
      items: [
        {
          item_id: variant?.sku,
          item_name: product?.name,
          item_brand: product?.vendor,
          item_category: '',
          item_variant: variant?.name,
          price: variant?.price,
        },
      ],
    },
  })
}
