import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'

const setAwsGaData = async ({ locale, eventList, userId, formatData }) => {
  const ipInfo = await ipinfo(locale)
  eventList[0].properties.country =
    ipInfo?.country || eventList[0].properties.country
  const url = `/api/multipass/rainbowbridge/personalize/event/tracking`
  const result = await fetcher({
    url,
    locale,
    method: 'PUT',
    body: {
      eventList,
      sessionId: userId || '-',
      userId: userId || '',
      shopify_domain: I18N_STORE_DOMAIN[locale],
    },
  })
  if (!result?.errors && result?.data) {
    const resultData = result?.data
    if (formatData) {
      return formatData(resultData)
    } else {
      return resultData
    }
  } else {
    return false
  }
}

const ipinfo = async (locale) => {
  const url = `/api/multipass/rainbowbridge/ipinfo`
  const result = await fetcher({
    url,
    locale,
    method: 'GET',
  })
  if (!result?.errors && result?.data) {
    return result?.data
  } else {
    return false
  }
}

export default setAwsGaData
